import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { IContentfulCountries } from '../queries-intefaces/countries';
import { Exchanges } from '../data/exchanges/exchanges';
import { Countries } from '../data/countries/countries';
import loadable from '@loadable/component';

export interface ICountryPageTemplate {
  data: any;
  location: any;
  allContentfulCurrency: any;
}


class CountryPageTemplate extends React.Component<ICountryPageTemplate, any> {
  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const ExchangeCountryTopServices = loadable(() => import ('../components/country-page/exchange-country-top-services/exchange-country-top-services'));
    const ExchangeCard = loadable(() => import ('../components/country-page/exchange-card/exchange-card'));
    const ExchangeSummary = loadable(() => import ('../components/country-page/exchange-summary/exchange-summary'));
    const TransactionSection = loadable(() => import ('../components/country-page/transaction-section/transaction-section'));
    const ExchangeList = loadable(() => import ('../components/country-page/exchange-list/exchange-list'));
    
    let addingExchanges = function(newEx){
      var data : any = {};
      data.code = newEx.name ? newEx.name : '';
      data.name = newEx.name ? newEx.name : '';
      data.numberOfUsers = newEx.numberOfUsers != '' ? newEx.numberOfUsers : '';
      data.verificationPeriod = newEx.verificationPeriod ? newEx.verificationPeriod.label : '';
      data.easyUsage = newEx.easyUsage ? newEx.easyUsage.label : '';
      data.hq = newEx.hq ? newEx.hq : '';
      data.support = newEx.support ? newEx.support : '';
      data.description = newEx.description ? newEx.description : '';
      data.founders = newEx.founders ? newEx.founders : '';
      data.reputation = newEx.reputation ? newEx.reputation : '';
      data.supportedFiat = newEx.supportedFiat ? newEx.supportedFiat.childMarkdownRemark.html : '';
      data.paymentMethods = newEx.paymentMethods ? newEx.paymentMethods.childMarkdownRemark.html : '';
      data.withdrawalFees = newEx.withdrawalFees ? newEx.withdrawalFees.childMarkdownRemark.html.replace("<p>", "").replace("</p>", "") : '';
      data.tradingFees = newEx.tradingFees ? newEx.tradingFees : '';
      data.sellingFees = newEx.sellingFees ? newEx.sellingFees : '';
      data.depositFees = newEx.depositFees ? newEx.depositFees.childMarkdownRemark.html.replace("<p>", "").replace("</p>", "") : '';
      data.reputation = newEx.reputation ? newEx.reputation : '';
      data.facebook = newEx.facebook ? newEx.facebook : '';
      data.linkedIn = newEx.linkedIn ? newEx.linkedIn : '';
      data.twitter = newEx.twitter ? newEx.twitter : '';
      data.url = newEx.url ? newEx.url : '';
      data.affiliateUrl = newEx.affiliateUrl ? newEx.affiliateUrl : '';
      data.icon = newEx.icon ? newEx.icon : null;

      data.feeRanking = newEx.feeRanking ? newEx.feeRanking : '';
      data.feeAverage = newEx.feeAverage ? newEx.feeAverage : getDynamicFeeAverage(newEx);
      exchangesFromJson.push(data);
    }

    let calculateDynamicAverages = function(makerString){
      let firstNumberMaker = makerString.search(/[0-9]/);
      let endFirstNumberMaker = makerString.search(/-/);
      let fistValueMaker = 0;

      if(makerString.substr(firstNumberMaker - 1, 1) == '.'){
        let tempfistValueMaker = makerString.substr(firstNumberMaker , endFirstNumberMaker - firstNumberMaker)
        fistValueMaker = parseFloat('0.'+tempfistValueMaker);
      }
      else{
        fistValueMaker = parseFloat(makerString.substr(firstNumberMaker , endFirstNumberMaker - firstNumberMaker));
      }
      
      let handlerMaker = makerString.substr(endFirstNumberMaker + 1, 100);
      let secondNumberMaker = handlerMaker.search(/[0-9]/);
      let endSecondNumberMaker = handlerMaker.search(/%/);
      let secondValueMaker = 0;

      if(handlerMaker.substr(secondNumberMaker - 1, 1) == '.'){
        let tempSecondValueMaker = handlerMaker.substr(secondNumberMaker , endSecondNumberMaker - secondNumberMaker)
        secondValueMaker = parseFloat('0.'+tempSecondValueMaker);
      }
      else{
        secondValueMaker = parseFloat(handlerMaker.substr(secondNumberMaker , endSecondNumberMaker - secondNumberMaker));
      }
      let makerAverage = (fistValueMaker + secondValueMaker) / 2;
      return makerAverage;
    }

    //average
    let getAveragesValue = function(feesExchange, firstNumberPos){
      let feeAverage = 0;
      let endFirstNumberPos = feesExchange.search(/%/);
      let fistValue= parseFloat(feesExchange.substr(firstNumberPos , endFirstNumberPos - firstNumberPos));
      let handlerFeesExchange = feesExchange.substr(endFirstNumberPos + 1, 100);
      let secondNumberPos = handlerFeesExchange.search(/[0-9]/);
      if(secondNumberPos >= 0){
        let endSecondNumberPos = handlerFeesExchange.search(/%/);
        let secondValue = handlerFeesExchange.substr(secondNumberPos , endSecondNumberPos - secondNumberPos);
        if(handlerFeesExchange.substr(secondNumberPos - 1, 1) == '.'){
          secondValue = handlerFeesExchange.slice(secondNumberPos - 2, 1) + "0" + handlerFeesExchange.slice(secondNumberPos - 1);
        }
        let limitSecondValue = parseFloat(secondValue.substr(0, secondValue.length));
        feeAverage = (fistValue + limitSecondValue) / 2;
      }
      else{
        feeAverage = fistValue;
      }
      return feeAverage;
    }

    //nueva
    let getDynamicFeeAverage = function(exchange) {
      let sellingFeesValue = 0;
      let tradingFeesValue = 0;
      let feeAverage = 0;
      let sellingFeesExchange = exchange.sellingFees != '' && exchange.sellingFees != null ? exchange.sellingFees.replace(/\n|\r/g, "").toString() : '';
      let tradingFeesExchange = exchange.tradingFees != '' && exchange.tradingFees != null ? exchange.tradingFees.replace(/\n|\r/g, "").toString() : '';

      if(sellingFeesExchange != ''){
        try {
          let firstNumberPosSelling = sellingFeesExchange.search(/[0-9]/);
          if(firstNumberPosSelling >= 0){
            sellingFeesValue = getAveragesValue(sellingFeesExchange, firstNumberPosSelling);
          }
          else{
            sellingFeesValue = 0
          }
        }
        catch(err){
          console.log("Error: Selling fees average of: " + exchange.name);
        }
      }

      if(tradingFeesExchange != ''){
        try {
          let firstNumberPosTrading = tradingFeesExchange.search(/[0-9]/);

          if(tradingFeesExchange.indexOf('Maker') > -1 && tradingFeesExchange.indexOf('Taker') > -1 && firstNumberPosTrading >= 0){
            let makerPos = tradingFeesExchange.indexOf('Maker');
            let takerPos = tradingFeesExchange.indexOf('Taker');
            let makerString =  tradingFeesExchange.substr(makerPos, takerPos-1);
            let takerString =  tradingFeesExchange.substr(takerPos, 20);
            let makerAverage = calculateDynamicAverages(makerString);
            let takerAverage = calculateDynamicAverages(takerString);
            tradingFeesValue = (makerAverage + takerAverage) / 2;
          }
          else if(tradingFeesExchange.indexOf('NONE') > -1 || tradingFeesExchange.indexOf('Free') > -1){
            sellingFeesValue = 0
          }
          else if(tradingFeesExchange.indexOf('Buy/Sell') > -1 && firstNumberPosTrading >= 0){
            let handlerString = tradingFeesExchange.substr(tradingFeesExchange.indexOf('Buy/Sell') , 20);
            firstNumberPosTrading = handlerString.search(/[0-9]/);
            let endFirstNumberPosTrading = handlerString.search(/%/);
            let valueBuySell = handlerString.substr(firstNumberPosTrading , endFirstNumberPosTrading - firstNumberPosTrading);
            tradingFeesValue = valueBuySell;
          }
          else if(firstNumberPosTrading >= 0){
            tradingFeesValue = getAveragesValue(tradingFeesExchange, firstNumberPosTrading);
          }
          else{
            tradingFeesValue = 0
          }
        }
        catch(err){
          console.log("Error: Trading fees average of: " + exchange.name);
        }
      }

      try {
        if(sellingFeesValue > 0 && tradingFeesValue > 0){
          feeAverage = parseFloat((sellingFeesValue + tradingFeesValue) / 2);
        }
        else if(sellingFeesValue > 0){
          feeAverage = parseFloat(sellingFeesValue);
        }
        else if(tradingFeesValue > 0){
          feeAverage = parseFloat(tradingFeesValue);
        }
      }
      catch(err){
        console.log("Error: fees average of: " + exchange.name);
      }
      return feeAverage.toFixed(2);

    }

    const countriesJsonList = Countries();
    const exchangeList = Exchanges();

    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    const currency = get(this.props, 'data.allContentfulCurrency');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const cryptoCards = get(this, 'props.data.allContentfulJourneyCards.nodes');
    const exchangeSummary = get(this, 'props.data.allContentfulExchangePage.nodes');
    const verificationOptions = get(this, 'props.data.allContentfulExchangeVerificationPeriod.nodes').sort((a, b) => {
      if (a.code > b.code) {
        return 1
      } else if (a.code < b.code) {
        return -1
      } else {
        return 0
      }
    });
    const paymentMethodsOptions = get(this, 'props.data.allContentfulExchangePaymentMethods.nodes').sort((a, b) => {
      if (a.code > b.code) {
        return 1
      } else if (a.code < b.code) {
        return -1
      } else {
        return 0
      }
    });
    const feesOptions = get(this, 'props.data.allContentfulExchangeFeesFilters.nodes').sort((a, b) => {
        if (a.code > b.code) {
          return 1
        } else if (a.code < b.code) {
          return -1
        } else {
          return 0
        }
    });
    const easyOfUseOptions = get(this, 'props.data.allContentfulExchangeEasyUsage.nodes').sort((a, b) => {
      if (a.code > b.code) {
        return 1
      } else if (a.code < b.code) {
        return -1
      } else {
        return 0
      }
    });

    const exchangesContenful = get(this.props, 'data.contentfulCountries.exchanges');
    const pathname = this.props.location.pathname.split('/');
    const country = countriesJsonList.filter(x => x.code == pathname[3])[0];
    const countryData = exchangeList.filter(x => x.country_code == (country.code).toUpperCase())
    const currencySelected = currency.nodes.filter(x => x.code==pathname[1]);
    const currencyName = currencySelected[0].name;
    const exchangesFromJson = countryData.length > 0 ? countryData[0].exchanges.filter(x => x.supported_coins?.indexOf(currencySelected[0].code.toUpperCase()) >= 0) : [];
    const exchangesFromContentful = !exchangesContenful ? [] : exchangesContenful.filter(x => {
      let temp = [];
      if(x.supportedCoins){
        temp = x.supportedCoins.filter(y => y.code.toUpperCase() == currencySelected[0].code.toUpperCase());
      }
      return temp.length > 0;
    });

    const setFeeAverage = exchangesFromJson.map(y => {
      y.feeAverage = getDynamicFeeAverage(y);
    });

    const exchangesContetfulData = exchangesFromJson.map(x => exchangesFromContentful.map(y => {
      if(x.name.toLowerCase() == y.code.toLowerCase()){
        //Replacing information from contentful
        x.numberOfUsers = y.numberOfUsers != '' ? y.numberOfUsers : x.numberOfUsers;
        x.verificationPeriod = y.verificationPeriod ? y.verificationPeriod.label : x.verificationPeriod;
        x.easyUsage = y.easyUsage ? y.easyUsage.label : x.easyUsage;
        x.hq = y.hq ? y.hq : x.hq;
        x.support = y.support ? y.support : x.support;
        x.description = y.description ? y.description : x.description;
        x.founders = y.founders ? y.founders : x.founders;
        x.reputation = y.reputation ? y.reputation : x.reputation;
        x.supportedFiats = y.supportedFiats ? y.supportedFiats.childMarkdownRemark.html : x.supportedFiats;
        x.paymentMethods = y.paymentMethods ? y.paymentMethods.childMarkdownRemark.html : x.paymentMethods;
        x.withdrawalFees = y.withdrawalFees ? y.withdrawalFees.childMarkdownRemark.html.replace("<p>", "").replace("</p>", "") : x.withdrawalFees;
        x.tradingFees = y.tradingFees ? y.tradingFees : x.tradingFees;
        x.sellingFees = y.sellingFees ? y.sellingFees : x.sellingFees;
        x.depositFees = y.depositFees ? y.depositFees.childMarkdownRemark.html.replace("<p>", "").replace("</p>", "") : x.depositFees;
        x.reputation = y.reputation ? y.reputation : x.reputation;

        //Adding information from contentful
        x.facebook = y.facebook ? y.facebook : '';
        x.linkedIn = y.linkedIn ? y.linkedIn : '';
        x.twitter = y.twitter ? y.twitter : '';
        x.url = y.url ? y.url : '';
        x.affiliateUrl = y.affiliateUrl ? y.affiliateUrl : '';
        x.icon = y.icon ? y.icon : null;
        x.feeRanking = y.feeRanking ? y.feeRanking : 'Low';
        x.feeAverage = y.feeAverage ? y.feeAverage : getDynamicFeeAverage(x);
      }
    }))

    let newExchanges = [];

    const setNewExchanges = function(){
      exchangesFromContentful.map(x => {
        let control = true;
        exchangesFromJson.map(y => {
          if(y.name.toLowerCase() == x.code.toLowerCase() && control){
            control = false;
          }
        })
        if(control){
          newExchanges.push(x);
        }
      })
    }

    setNewExchanges();

    if(newExchanges.length > 0){
      newExchanges.map(x => addingExchanges(x))
    }

    exchangeSummary[0].titleFormatted = (exchangesFromJson.length > 0 ? exchangeSummary[0].title : '') + currencyName + ' in ' ;
    exchangeSummary[0].topExchangesSectionTitleFormatted = exchangeSummary[0].topExchangesSectionTitle + currencyName + ' Exchanges in ';
    
    return (
      <Layout className='violet-style' footer={footerData} header={headerData}>
        <Helmet title={`${country.name} | ${siteTitle}`} />
        <div style={{ background: '#fff' }} className="country-page-countainer">
          <div className="top-image"></div>
          <ExchangeSummary header={exchangeSummary[0]}
            countryData={country}
            exchanges={exchangesFromJson}
          />
          { exchangesFromJson.length > 0 &&
            <ExchangeCountryTopServices topExchangesSectionTitle={exchangeSummary[0].topExchangesSectionTitleFormatted}
              countryData={country}
              exchanges={exchangesFromJson}
            />
          }
          { exchangesFromJson.length > 0 &&
            <ExchangeList 
              easyOfUseOptions={easyOfUseOptions} 
              verificationOptions={verificationOptions} 
              paymentMethodsOptions={paymentMethodsOptions}
              feesOptions={feesOptions}
              exchanges={exchangesFromJson}
              currency={currencySelected}/>
          }
          <TransactionSection cryptoCardsData={cryptoCards} />
        </div>
      </Layout>
    );
  }
}
export default CountryPageTemplate;

export const pageQuery = graphql`
  query CountryByCode($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCountries(code: { eq: $slug }) {
      name
      code
      exchanges {
        id
        code
        name
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description
        url
        affiliateUrl
        facebook
        twitter
        linkedIn
        numberOfUsers
        type
        hq
        nftTypes
        depositFees {
          childMarkdownRemark{
            html
          }
        }
        feeRanking
        feeAverage
        paymentMethods {
          childMarkdownRemark{
            html
          }
        }
        reputation
        sellingFees
        support
        supportedFiat {
          childMarkdownRemark{
            html
          }
        }
        tradingFees
        withdrawalFees {
          childMarkdownRemark{
            html
          }
        }
        founders{
          name
          link
        }
        affiliateUrl
        easyUsage {
          code
          label
        }
        verificationPeriod {
          code
          label
        }
        countries{
          code
        }
        supportedCoins{
          code
        }
      }
    }
    allContentfulJourneyCards {
      totalCount
      nodes {
        id
        createdAt
        enabled
        linkText
        linkUrl
        title
        paragraph {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulExchangePage {
      nodes {
        title
        subtitle {
          childMarkdownRemark {
            html
          }
        }
        topExchangesSectionTitle
      }
    }
    allContentfulExchangeVerificationPeriod {
      nodes {
        code
        label
      }
    }
    allContentfulExchangeEasyUsage {
      nodes {
        code
        label
      }
    }
    allContentfulExchangePaymentMethods {
      nodes {
        code
        label
        additionalValues
      }
    }
    allContentfulExchangeFeesFilters {
      nodes {
        code
        label
      }
    }
    allContentfulFounder {
      nodes {
        link
        name
      }
    }
    allContentfulCurrency {
      nodes {
        code
        name
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
